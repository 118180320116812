.spot-position-box {
    width: 100%;
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #fbfbfb;
    color: #000;
    margin-bottom: 16px;
    text-align: center;
    border-radius: 8px;
    border: 1px solid #efecec;
    align-items: center;
    transition:all .22s linear;
    padding-bottom:10px;

    .btn-add-process {
        position: absolute;
        top: 11px;
        right: 20px;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        font-size: 20px;
        padding: 0;
        font-weight: 700;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
            display:inline-block;
        }
    }

    .currency-image {
        color: #000;
        img {
            height: 80px;
            margin-bottom:10px;
        }
        p {
            margin:0;
            padding:0;
        }
        .symbol-name {
            font-size:18px;
        }
        .full-name {}

        .red {
            background-color: #f50;
            border-color:#f56e2a;
        }
        .green {
            background-color: #2dd92a;
            border-color:#6bff68;
        }
        .yellow {
            background-color: #ffcc12;
            border-color:#f7cb58;
        }
        .star {
            background-color: inherit;
            border-color: transparent;
            width: auto;
            height: auto;
            .anticon {
            }
        }
    }

    .currency-price {
        margin:10px 0;
        p {
            font-weight: 700;
            font-size:25px;
            margin:0;
            padding:0;
            &.green {
                color:#2db52d;
            }
            &.red {
                color:#f6465d;
            }
        }
    }

    .current-balance {
        border: 1px solid #dfdbdb;
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px dashed #ededed;
        background: #fff;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        box-shadow: inset 0 3px 5px rgb(0 0 0 / 10%);
        .label {
            font-size:12px;
            font-weight:500;
            padding-bottom: 5px;
            position: relative;
            &:after {
                content:'';
                position:absolute;
                background-color: #e5ac17;
                width:30px;
                height:1px;
                bottom:0;
                left:0;
                right:0;
                margin:0 auto;
            }
        }
        .value {
            font-size:14px;
            font-weight: 700;
        }
    }

    .pnl-stats {
        width: 100%;
        padding: 10px 0;
        background: #FFF;
        border: 1px solid #dfdbdb;
        border-top: none;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
        box-shadow: inset 0 -3px 5px rgb(0 0 0 / 10%);
        .label {
            font-size:12px;
            font-weight:500;
            padding-bottom: 3px;
            position: relative;
            &:after {
                content:'';
                position:absolute;
                background-color: #e5ac17;
                width:30px;
                height:1px;
                bottom:0;
                left:0;
                right:0;
                margin:0 auto;
            }
        }
        .value {
            font-size:18px;
            font-weight: 700;
            &.green {
                color:#2db52d;
            }
            &.red {
                color:#f6465d;
            }
        }
    }

    .history {
        width:100%;
        padding-top: 10px;
    }

    .risk {
        width: 10px;
        height: 10px;
        background: #333;
        display: block;
        margin-left: 5px;
        border-radius: 50%;
        //border: 1px solid #333;
        position: absolute;
        top: 10px;
        right: 20px;
    }

}

a:hover {
    .coin-list-box {
        box-shadow: 0 0 20px rgb(0 0 0 / 20%);
        border-color: #dddddd;
        background: #FFF;
    }
}


.spot-position-box-new {
    width: 100%;
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #fbfbfb;
    color: #000;
    margin-bottom: 16px;
    text-align: center;
    border-radius: 8px;
    border: 1px solid #efecec;
    align-items: center;
    transition:all .22s linear;
    padding-bottom:10px;
    &.short {
        border-color: #e5ac17;
    }

    .pos-period {
        position: absolute;
        top: 8px;
        left: 20px;
        .mid {
            color: #e5ac17;
        }
        .short {
            color:#ff6406;
        }
        img {
            height:20px;
        }
    }

    .btn-add-process {
        position: absolute;
        top: 11px;
        right: 20px;
        span {
            display:inline-block;
        }
    }

    .currency-base {
        margin: 0;
    }
    .currency-image {
        color: #000;
        img {
            height: 60px;
            margin-bottom:10px;
            width:70px;
        }
        p {
            margin:0;
            padding:0;
        }
        .symbol-name {
            font-size:18px;
        }
        .full-name {}

        .red {
            background-color: #f50;
            border-color:#f56e2a;
        }
        .green {
            background-color: #2dd92a;
            border-color:#6bff68;
        }
        .yellow {
            background-color: #ffcc12;
            border-color:#f7cb58;
        }
        .star {
            background-color: inherit;
            border-color: transparent;
            width: auto;
            height: auto;
            .anticon {
            }
        }
    }

    .currency-price {
        margin:10px 0;
        p {
            font-weight: 700;
            font-size:20px;
            margin:0;
            padding:0;
            &.green {
                color:#2dbd2d;
            }
            &.red {
                color:#f6465d;
            }
        }
    }

    .currency-details {
        margin: 0;
        width:100%;
    }

    .current-balance {
        border: 1px solid #dfdbdb;
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px dashed #ededed;
        background: #fff;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        box-shadow: inset 0 3px 5px rgb(0 0 0 / 3%);
        .label {
            font-size:12px;
            font-weight:500;
            padding-bottom: 5px;
            position: relative;
            &:after {
                content:'';
                position:absolute;
                background-color: #e5ac17;
                width:30px;
                height:1px;
                bottom:0;
                left:0;
                right:0;
                margin:0 auto;
            }
        }
        .value {
            font-size:13px;
            font-weight: 600;
        }
    }

    .pnl-stats {
        width: 100%;
        padding: 10px 0;
        background: #FFF;
        border: 1px solid #dfdbdb;
        border-top: none;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
        box-shadow: inset 0 -3px 5px rgb(0 0 0 / 3%);
        .label {
            font-size:12px;
            font-weight:500;
            padding-bottom: 3px;
            position: relative;
            &:after {
                content:'';
                position:absolute;
                background-color: #e5ac17;
                width:30px;
                height:1px;
                bottom:0;
                left:0;
                right:0;
                margin:0 auto;
            }
        }
        .value {
            font-size:14px;
            font-weight: 700;
            &.green {
                color:#2dbd2d;
            }
            &.red {
                color:#f6465d;
            }
        }
    }

    .history {
        width:100%;
        padding-top: 10px;
        .mobile-add {
            display:none;
        }
        .web-text {
            display: inline;
        }
        .mobile-text {
            display:none;
        }
    }

    .risk {
        width: 10px;
        height: 10px;
        background: #333;
        display: block;
        margin-left: 5px;
        border-radius: 50%;
        //border: 1px solid #333;
        position: absolute;
        top: 10px;
        right: 20px;
    }

}

a:hover {
    .coin-list-box {
        box-shadow: 0 0 20px rgb(0 0 0 / 20%);
        border-color: #dddddd;
        background: #FFF;
    }
}

@media screen and (max-width: 768px) {
    .spot-position-box-new {
        flex-direction: unset;
        flex-wrap: wrap;
        padding: 10px;
    
        .btn-add-process {
            display:none;
        }
    
        .currency-base {
            margin: 0;
            width: 35%;
            display: flex;
            flex-direction: column;
            padding-right: 10px;
        }
        .currency-image {
            img {
                height: 50px;
                margin-bottom: 5px;
                width:60px;
            }
            .symbol-name {
                font-size:14px;
            }
            .full-name {
                display:none;
            }
        }   
        .currency-price {
            margin:0;
            p {
                font-size: 16px;
            }
        }
    
        .currency-details {
            display: flex;
            width: 65%;
            flex-direction: column;
        }
    
        .current-balance {
            padding: 5px 8px;
            .label {
                padding-bottom: 0;
                widtH: 50%;
                float: left;
                text-align: left;
                &:after {
                    display:none;
                }
            }
            .value {
                float: left;
                width: 50%;
                text-align: right;
            }
        }
    
        .pnl-stats {
            padding: 5px 8px;
            .label {
                float: left;
                width: 50%;
                text-align: left;
                &:after {
                    display:none;
                }
            }
            .value {
                text-align: right;
                float: left;
                width: 50%;
                &.green {
                    color:#2db52d;
                }
                &.red {
                    color:#f6465d;   
                }
            }
            .c-label {
                display:none;
            }
            .c-value {
                padding-top: 5px;
                text-align: center;
                width:100%;
            }
        }
    
        .history {
            width:100%;
            padding-top: 10px;
            .mobile-add {
                display:block;
            }
            .web-text {
                display: none;
            }
            .mobile-text {
                display:inline;
            }
        }
    
        .risk {
            width: 10px;
            height: 10px;
            background: #333;
            display: block;
            margin-left: 5px;
            border-radius: 50%;
            //border: 1px solid #333;
            position: absolute;
            top: 10px;
            right: 20px;
        }
    
    }
    
}
