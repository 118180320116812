.ant-menu {
    width:100%;
}

.header-logo {
    display:flex;
    img {
        max-width:180px;
    }
} 

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
    background-color: #E5AC17;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected, 
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    background-color: #E5AC17;
}

.mobile-menu-icon {
    display: none;
    position: absolute;
    left: 0;
    padding: 23px;
}

@media screen and (max-width: 768px) {
    .mobile-menu-icon {
        display: flex;
    }
}
