.account-box {
    align-items: center;
    background: #fbfbfb;
    border: 1px solid #efecec;
    border-radius: 8px;
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    transition: all .22s linear;
    width: 100%;
    overflow: hidden;

    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    .header {
        background: #282828;
        border-bottom: 1px solid #ffffff;
        width: 100%;
        padding:10px;
        text-align: left;
        display:flex;
        align-items: center;
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
        img {
            max-width: 25px;
        }
        p {
            display:inline-block;
            margin:0;
            font-size:18px;
            font-weight: 700;
            color:#f1f1f1;
            padding-left:10px;
            line-height: 1;
        }
        span {
            display: inline-block;
            margin-left: 0;
            left:0;
            color:#F1f1f1;
            position: relative;
            font-size:12px;
            left:4px;
            top:2px;
            line-height: 1.4;

        }
        .main-balance {
            margin-left: auto;
            display: flex;
            flex-direction: column;
            margin-right: 10px;
            align-items: center;
            span {
                display:block;
                font-size: 12px;
                font-weight: 600;
            }
        }
    }
    .body {
        padding: 10px;
        width:100%;
        .price-box {
            background: #fff;
            border: 1px solid #dfdbdb;
            border-radius: 4px;
            box-shadow: inset 0 3px 5px #00000008;
            padding-bottom: 10px;
            padding-top: 10px;
            width: 100%;
            margin-bottom:10px;
            &:last-child {
                margin-bottom: 0;
            }
            .icon {
                margin-left:5px;
                cursor: pointer;
                font-size:13px;
            }
            &.s2 {
                .label {
                    font-size: 13px !important;
                }
                .value {
                    font-size:14px;
                    font-weight: 700;
                }
            }
            .label {
                font-size:12px;
                font-weight:500;
                padding-bottom: 5px;
                position: relative;
                display:flex;
                align-items: center;
                justify-content: center;
                &:after {
                    content:'';
                    position:absolute;
                    background-color: #e5ac17;
                    width:30px;
                    height:1px;
                    bottom:0;
                    left:0;
                    right:0;
                    margin:0 auto;
                }
            }
            .value {
                font-size:13px;
                font-weight: 600;
                &.green {
                    color:#2db52d;
                }
                &.red {
                    color:#f6465d;   
                }
            }
        }
    }

    span {
        display: block;
    }
    p {
        margin:0;
    }
}

.past-pnl-modal {
    .ant-col {
        margin-bottom:10px;
    }   
    .form-label {
        width: 100%;
        text-align: left;
        display: inline-block;
        margin-bottom: 3px;
    }
    .ant-input-number {
        width: 100%;
        margin-bottom: 0px;
        .ant-input-number-handler-wrap {
            display:none;
        }
        input {
            margin-bottom:0;
        }
    }

    .ant-form-item-control {
        margin:0;
    }

    .ant-form-item {
        margin-bottom:0;
    }

    .ant-form-item-label {
        padding:0;
        margin-bottom:3px;
    }

    .ant-form-item-required {
        &:before {
            content: '' !important;
            margin-right: 0 !important;
        }
    }

    .btn-add-process {
        margin-top:10px;
    }

    p {
        margin:0;
    }
}

@media screen and (max-width: 768px) {
    .account-box {
        margin-bottom:20px;
    }
}