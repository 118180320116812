.sr-deals {
    display: flex;
    align-items:flex-start;
    padding: 10px 15px;
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    margin-bottom:15px;
    flex-direction: column;
    .head {
        display:flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        border-bottom: 1px dashed #f0f0f0;
        margin-bottom: 10px;
        padding-bottom: 10px;
        a {
            color:#000;
            display:flex;
            align-items: center;
        }
        img {
            height: 18px;
            margin-right: 6px;
        }
        .tw {
            height:24px;
            margin-right: 0;
        }
    }

    .lines {
        height:70px;
        display: flex;
        align-items: center;
        text-align: left;
        img {
            height: 100%;
            margin-right: 10px;
            background: #fbfbfb;
            padding: 5px;
            border: 1px solid #f1efef;
        }
        .line-text {
            font-size: 12px;
            color: #cccccc;
            span {
                display:block;
            }
            .bold  {
                font-size: 13px;
                font-weight:600;
                margin-right:10px;
                color: #333;
                display: inline;
            }
        }
    }
}
