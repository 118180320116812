.logo {
    margin-right:20px;
}

@media screen and (max-width: 1601px) {
    .logo {
        display: flex;
        align-items: center;
    }
}

@media screen and (max-width: 768px) {
    .logo {
        margin:0;
    }
}
