.navigation {
    width:auto;
    display: flex;
    align-items: center;
    ul {
        padding:0;
        margin:0;
        display:flex;
        li {
            list-style: none;
            a {
                display:block;
                padding:0 15px;
                color:#FFF;
                transition: all 0.2s linear;

            }
            &:hover {
                a {
                    background-color: #E5AC17;   
                }
            }
            &.active {
                a {
                    background-color: #E5AC17;
                }
            }
        }
    }
    .calc-icon {
        display: flex;
        padding: 0 20px;
        span {
            cursor:pointer;
        }
    }
    .current-model {
        display: flex;
        align-items: center;
        p {color: #FFF; margin:0; display: inline-block; line-height: 1.5; }
    } 
}

@media screen and (max-width: 768px) {
    .ant-layout-header {
        justify-content: center;
    }
    .navigation {
        display: none;
    }
}
