.private-key-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: #282828;
    .enter-logo {
        margin-bottom:15px;
        img {
            max-width: 280px;

        }
    }
    .logo {
        padding:0;
        margin:0;
    }
    p {
        color:#FFF;
    }
    input {
        width:300px;
        height:60px;
        border-radius: 20px;
        border:3px solid #b5b5b5;
        text-align: center;
        &:focus, &:hover {
            border-color: #e5ac17;
            border-right-width: 3px !important;
        }
        &:focus {
            outline: 7px solid rgb(82 82 82 / 30%);
        }
    }
}

.google-auth-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: #282828;
    button {
        width:300px;
        height:50px;
        border-radius: 10px;
        background-color: #e5ac17;
        border-color: #e5ac17;
        font-weight: 500;
        color:#FFF;
        &:hover {
            background-color: #f7931a;
            border-color: #f7931a; 
            color:#FFF;
        }
    }
}