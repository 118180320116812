.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.ant-layout-header {
  display:flex;
  background-color: #282828;
  height: 70px;
  line-height: 70px;
}

.ant-layout-content {
  min-height: calc(100vh - 116px);
  padding: 24px;
  background: #fff;
  position:relative;
}

.container {
  width:1200px;
  margin:0 auto;
  display:block;
}

.container-xl {
  width:1600px;
  margin:0 auto;
  display:block;
}

.container-fluid {
  width:100%;
  display:block;
}

.ant-table-tbody tr td {
  font-weight: 500;
  font-size: 13px;
  padding:16px 12px !important;
}


.ant-table-row {
  &.soft-red {
    background: #fff1f1;
  }
  &.soft-green {
    background: #f0ffef;
  }
  &.soft-gray {
    background: #e7e7e7;
  }
}

.ant-col-xs-5 {
  flex: 0 0 20% !important;
  max-width: 20% !important;
}

.btn-cryptoverse {
  background-color: #e5ac17;
  border-color: #e5ac17;
  transition:all 0.2s linear;
  &:active {
    background-color: #e5ac17;
    border-color: #e5ac17;
  }
  &:hover, &:focus {
      background-color: #f7931a;
      border-color: #f7931a;         
  }
}

.btn-black {
  background-color: #212121;
  border-color: #212121;
  color:#FFF;
  transition:all 0.2s linear;
  &:active {
    background-color: #212121;
    border-color: #212121;
    color:#FFF;
  }
  &:hover, &:focus {
    background-color: #3f3f3f;
    border-color: #212121; 
    color:#FFF;
      
  }
}

.btn-gray {
  background-color: #f9f9f9;
  border-color: #e7e7e7;
  color: #838383;
  transition:all 0.2s linear;
  &:active {
    background-color: #f9f9f9;
    border-color: #e7e7e7;
    color: #838383;
  }
  &:hover, &:focus {
    background-color: #ebebeb;
    border-color: #e7e7e7;
    color: #838383;
  }
}

.btn-green {
  background-color: #23a946;
  border-color: #23a946;
  transition:all 0.2s linear;
  &:active {
    background-color: #23a946;
    border-color: #23a946;
  }
  &:hover, &:focus {
    background-color: #25c74f;
    border-color: #25c74f;      
  }
}

.btn-red {
  background-color: #ff4d4f;
  border-color: #ff4d4f;
  transition:all 0.2s linear;
  &:active {
    background-color: #ff4d4f;
    border-color: #ff4d4f;  
  }
  &:hover, &:focus {
    background-color: #cb3133;
    border-color: #ff4d4f;     
  }
}

@media screen and (max-width: 1201px) {
  .container {
    width:100%;
  }

  .container-xl {
    width:100%;
  }
}

@media screen and (min-width: 768px) {
  .ant-col-md-5 {
    flex: 0 0 20% !important;
    max-width: 20% !important;
  }
}

@media screen and (max-width: 768px) {
  .ant-layout-content {
    padding: 10px 15px;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
