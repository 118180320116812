.user-info {
    margin-left: auto;
    display: flex;
    background-color: #ffffff;
    height: 80%;
    margin-top: 7px;
    border-radius: 10px;
    align-items: center;
    color: #000;
    padding: 0 10px;
    justify-content: space-between;
    .ant-avatar {

    }
    .user-name {
        color: #000;
        font-size: 12px;
        flex-grow: 1;
        flex-shrink: 1;
        margin: 0 10px;
        line-height: 20px;
        text-align: left;
        p {
            margin: 0;
            padding: 0;
            font-style: italic;
            font-size:12px;
        }
        h4 {
            margin: 0;
            padding: 0;
            font-size: 14px;
        }
    }
    .ant-btn {
        background-color: #4b4b4b;
        border-color: #4b4b4b;
        width: 20px;
        height: 20px;
        min-width: 20px;
        &:hover {
            background-color: #e72525;
            border-color: #e72525;
        }
        .anticon-close {
            font-size: 10px;
        }     
    }
}
@media screen and (max-width: 768px) {
    .user-info {
        display: none;
    }
}
