.accounts {
    .account-header {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        .main-title {
            -webkit-text-fill-color: rgba(0, 0, 0, 0);
            background: -webkit-linear-gradient(0deg, #e5ac17, #f7931a 200%);
            -webkit-background-clip: text;
            font-size: 47px;
            font-weight: 900;
            letter-spacing: -3px;
            margin: 0;
            text-align: left;
            font-size: 47px;
            font-weight: 900;
            display: inline-block;
            padding-right: 10px;
        }
        .balance-actions {
            margin-left:auto;
            .ant-btn {
                margin-left: 10px;
            }
        }
    }
    .overall-balance {
        background: #fff;
        border: 1px solid #dfdfdf;
        border-radius: 8px;
        box-shadow: 0 0 22px 0 #0000001f;
        display: block;
        width:100%;
        margin-bottom: 30px;
        .ant-col {
            &:after {
                position: absolute;
                right: 0;
                content: "";
                width: 1px;
                height: 100%;
                top:0;
                background: #dfdfdf;
            }
            &:last-child {
                &:after {
                    content:'';
                    display:none;
                }
            }
        }
        .balance {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            flex-grow: 1;
            /* border-left: 1px solid #dfdfdf; */
            /* border-right: 1px solid #dfdfdf; */
            padding: 20px;
            &:after {
                position: absolute;
                right: 0;
                content: "";
                width: 1px;
                height: 30px;
                top: calc(50% - 15px);
                background: #d9d9d9;
            }
            &:last-child {
                &:after {
                    content:'';
                    display:none;
                }
            }
            .label {
                font-size: 14px;
                font-weight: 500;
                display: flex;
                align-items: center;
                line-height: 1;
                margin-bottom:5px;
                &.green {
                    color:#2db52d;
                }
                &.red {
                    color:#f6465d;   
                }
                .anticon {
                    margin-left: 5px;
                }
            }
            .value {
                font-size: 20px;
                font-weight: 700;
                line-height: 1;
            }
        }
    }
    .ant-input-number {
        width: 100%;
        margin-bottom: 10px;
        .ant-input-number-handler-wrap {
            display:none;
        }
        input {
            margin-bottom:0;
        }
    }
    .account-starting {
        max-width: 350px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        .create-new-account {
            padding:20px;
            border-radius: 8px;
            background: #fbfbfb;
            border: 1px solid #efecec;
            text-align: left;
            margin-bottom:20px;
            span {
                display: inline-block;
                padding-top:5px;
                font-size:13px;
                font-style: italic;
            }
        }
        .form-label {
            width: 100%;
            text-align: left;
            display: inline-block;
            margin-bottom: 3px;
        }
    }


    .green {
        color:#2db52d;
    }
    .red {
        color:#f6465d;
    }
    .wallet-header {
        .ant-row {
            align-items:center;
        }
        .wallet-title {
            //color: #e5ac17;
            font-size: 47px;
            font-weight: 900;
            margin: 0;
            letter-spacing: -3px;
            text-align: left;
            background: -webkit-linear-gradient(0deg, #e5ac17, #f7931a 200%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            span {
                //color: #f7931a;
            }
        }
        .realized {
            font-size: 16px;
            text-align: left;
            font-weight: 400;
            margin: 0;
            display: inline-block;
            //border: 1px solid #ededed;
            padding: 10px 15px;
            span {
                font-weight: 700;
                color: #2ca52c;
            }
        }
        .active-balance {
            margin: 0;
            font-size: 16px;
            font-weight: 400;
            text-align: left;
            display: inline-block;
            //border: 1px solid #ededed;
            padding: 10px 15px; 
            border-left: 1px solid #dfdfdf;
            border-right: 1px solid #dfdfdf;
            span {
                font-weight: 700;
            }      
        }
        .current-pnl {
            margin: 0;
            font-size: 16px;
            font-weight: 400;
            text-align: left;
            display: inline-block;
            //border: 1px solid #ededed;
            padding: 10px 15px;
            span {
                font-weight: 700;
            }
        }
        .item.count {
            text-align: left;
            font-size: 16px;
            display: flex;
            p {
                display:inline-block;
                margin-right:10px;
            }
            span {
                font-weight:700;
            }
            .market-list {
                span {
                    font-weight:500;
                }
            }
        }
        .switch-mode {
            display: flex;
            justify-content: end;
            margin-bottom: 10px;
            .ant-switch {
                background-color: #E5AC17;
            }
        }

        .wallet-action {
            display:flex;
            justify-content: flex-end;
            .ant-btn {
                margin-left:10px;
            }
        }

        .price-area {
            display: inline-block;
            background: #ffffff;
            border: 1px solid #dfdfdf;
            border-radius: 8px;
            -webkit-box-shadow: 0px 0px 22px 0px rgba(0,0,0,0.32);
            -moz-box-shadow: 0px 0px 22px 0px rgba(0,0,0,0.32);
            -webkit-box-shadow: 0px 0px 22px 0px rgba(0,0,0,0.2);
            -moz-box-shadow: 0px 0px 22px 0px rgba(0,0,0,0.2);
            box-shadow: 0px 0px 22px 0px rgba(0,0,0,0.12);
        }
    }
    .currency-search {
        width: 50%;
        margin: 0 auto;
        padding: 20px;
        margin-bottom: 20px;
        .ant-input-affix-wrapper {
            border-radius: 6px;
        }
        .ant-input {
            line-height: 40px;
        }
    }
}

.new-balance-modal {
    .ant-col {
        margin-bottom:10px;
    }   
    .form-label {
        width: 100%;
        text-align: left;
        display: inline-block;
        margin-bottom: 3px;
    }
    .ant-input-number {
        width: 100%;
        margin-bottom: 0px;
        .ant-input-number-handler-wrap {
            display:none;
        }
        input {
            margin-bottom:0;
        }
    }

    .ant-form-item-control {
        margin:0;
    }

    .ant-form-item {
        margin-bottom:0;
    }

    .ant-form-item-label {
        padding:0;
        margin-bottom:3px;
    }

    .ant-form-item-required {
        &:before {
            content: '' !important;
            margin-right: 0 !important;
        }
    }

    .btn-add-process {
        margin-top:10px;
    }

    p {
        margin:0;
    }
}

@media screen and (max-width: 768px) {
    .accounts {
        display: flex;
        flex-wrap: wrap;
        .account-header {
            flex-direction: column;
            .main-title {
                width: 100%;
                font-size: 40px;
                text-align: center;
                padding-right: 0;
            }
            .balance-actions {
                width:100%;
                .ant-btn {
                    margin-left: 10px;
                    width: calc(50% - 10px);
                    margin-bottom: 10px;
                }
                .btn-black {
                    width:calc(100% - 10px);
                }
            }
        }
    }
}