.add-transfer-modal {
    .ant-col {
        margin-bottom:10px;
    }
    .select-period {
        .ant-radio-group {
            display: flex;
            justify-content:start;
            flex-wrap: wrap;
            gap: 10px;
            .ant-radio-button-wrapper {
                width:calc(32.67% - 5px);
                text-align: center;
            }
        }
    }

    .select-side {
        .ant-radio-group {
            display: flex;
            justify-content:start;
            flex-wrap: wrap;
            gap: 10px;
            .ant-radio-button-wrapper {
                width:calc(50% - 5px);
                text-align: center;
            }
        }
        .ant-radio-group-solid .ant-radio-button-wrapper:first-child.ant-radio-button-wrapper-checked {
            color: #fff;
            background-color: #25c74f;
            border-color: #25c74f;  
        }
    
        .ant-radio-group-solid .ant-radio-button-wrapper:last-child.ant-radio-button-wrapper-checked {
            color: #fff;
            background-color: #cb3133;
            border-color: #ff4d4f;  
        }
    }

    .form-label {
        width: 100%;
        text-align: left;
        display: inline-block;
        margin-bottom: 3px;
    }
    .ant-input-number {
        width: 100%;
        margin-bottom: 0px;
        .ant-input-number-handler-wrap {
            display:none;
        }
        input {
            margin-bottom:0;
        }
    }

    .ant-form-item-control {
        margin:0;
    }

    .ant-form-item {
        margin-bottom:0;
    }

    .ant-form-item-label {
        padding:0;
        margin-bottom:3px;
    }

    .ant-form-item-required {
        &:before {
            content: '' !important;
            margin-right: 0 !important;
        }
    }

    .available {
        margin:0;
        padding-top: 8px;
        span {
            font-weight: 700;
            color:#2db52d;
        }
    }

    .btn-add-process {
        margin-top:10px;
    }
}