.last-24-hours-deals-table {
    .ant-tag {
        font-size: 13px;
        padding: 5px 10px;
        border-radius: 4px;
        font-weight: 500;
    }

    .ant-table-tbody {
        tr {
            td {
                font-weight:500;
            }
        }
    }

    .symbol {
        display: flex;
        align-items: center;
        a {
            color:#000;
        }
        img {
            height: 18px;
            margin-right: 6px;
        }
        span {

        }
    }

    .reverse {
        display: flex;
        justify-content: space-between;
        align-items: center;
        span.reverse-index {
            background: #ff5a5a;
            color: #fff;
            font-size: 11px;
            display: flex;
            height: 20px;
            text-align: center;
            justify-content: center;
            align-items: center;
            line-height: 0;
            position: absolute;
            right: 0;
            top: 0;
            padding: 0 5px;
            border-bottom-left-radius: 4px;
        }
    }

    .deal-strength {
        display: flex;
        justify-content: center;
        font-weight: 700 !important;
        .CircularProgressbar-text {
            dominant-baseline: central;
        }
    }
}