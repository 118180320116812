.process-history-modal {
    .ant-col {
        margin-bottom:10px;
    }
    /*
    .ant-table-content {
        overflow-y: auto;
        max-height: 400px;
    }*/
    .ant-table-measure-row {
        td {
            padding: 0 !important;
        }
    }
    .ant-radio-group {
        display: flex;
        justify-content:start;
        flex-wrap: wrap;
        gap: 10px;
        .ant-radio-button-wrapper {
            width:calc(50% - 5px);
            text-align: center;
        }
    }
    .form-label {
        width: 100%;
        text-align: left;
        display: inline-block;
        margin-bottom: 3px;
    }
    .ant-input-number {
        width: 100%;
        margin-bottom: 10px;
        .ant-input-number-handler-wrap {
            display:none;
        }
        input {
            margin-bottom:0;
        }
    }
    .action {
        text-align:center;
    }
    .history-image {
        text-align: center;
        img {
            height:100px;
        }
        p {
            margin:0;
        }
    }
    .history-info {
        margin-bottom:10px;
    }
    .history-prices {
        display: flex;
        flex-direction: column;
        justify-content: center;
        p {
            font-weight:400;
            font-size:16px;
            margin: 0;
            border: 1px solid #e9e9e9;
            padding: 10px 15px;
            span {
                font-weight:600;
            }
        }
    }
}