.account-group {
    width: 100%;
    border: 2px solid #ebebeb;
    border-radius: 8px;
    margin-bottom: 20px;
    overflow: hidden;
    .group-header {
        background: #f7f7f7;
        //border-bottom: 1px solid #f3f3f3;
        width: 100%;
        padding: 10px;
        text-align: left;
        display: flex;
        align-items: center;
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
        overflow: hidden;
        border-bottom: 1px solid #ffffff;
        background: rgb(213, 213, 213);
        background: linear-gradient(0deg, rgba(226, 226, 226, 1) 0%, rgba(247, 247, 247, 0) 100%);
        .info {
            display:flex;
            margin-right: 20px;
            align-items: center;
            img {
                max-width: 30px;
            }
            p {
                display:inline-block;
                margin:0;
                font-size:18px;
                font-weight: 700;
                color:#222;
                padding-left:10px;
                line-height: 1;

            }
            span {
                display: inline-block;
                margin-left: 0;
                color:#222;
                position: relative;
                font-size:16px;
                left:4px;
                line-height: 1;

            }
        }
        .balances {
            display: flex;
            justify-content: space-evenly;
            flex-grow: 1;
            .balance {
                display: flex;
                flex-direction: column;
                margin-right: 10px;
                align-items: center;
                position:relative;
                flex-grow: 1;
                &:after {
                    position: absolute;
                    right: 0;
                    content: "";
                    width: 1px;
                    height: 30px;
                    top: calc(50% - 15px);
                    background: #d9d9d9;
                }
                &:last-child {
                    &:after {
                        content:'';
                        display:none;
                    }
                }
                span {
                    display:block;
                    &.label {
                        font-size: 13px;
                        font-weight: 500;
                    }
                    &.value {
                        font-size: 16px;
                        font-weight: 700;
                    }
                    &.green {
                        color:#2db52d;
                    }
                    &.red {
                        color:#f6465d;   
                    }
                }
                
            }
        }

    }
    .group-body {
        padding:20px;

        background: #f9f9f9;
        box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
    }
}

@media screen and (max-width: 768px) {
    .account-group {
        .group-header {
            flex-direction: column;
            .info {
                display: flex;
                margin-right: 0;
                margin-bottom: 10px;
                align-items: center;
            }
            .balances {
                overflow-x: auto;
                width: 100%;
                .balance {
                    margin-left:10px;
                    &:after {
                        right: -10px;
                    }
                    .label {
                        min-width: 100px;
                        text-align: center;
                    }
                }
            }
        }
        .group-body {
            padding-bottom:0px;
        }
    }
}